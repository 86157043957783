const PROJECT = process.env.REACT_APP_PROJECT || "first-metal"

console.log(`Loaded project: ${PROJECT}`)

const LAYOUTS = {
  "first-metal": {
    header: {
      active: true,
      logo: {
        target: "/",
        height: "29px",
        width: "180px",
        alt: "First Metal",
        image: "/image/header_logo.svg"
      },
      navLabel: "First Metal navigation menu",
      navigation: [
        {
          id: "nosotros",
          label: "Nosotros",
          type: "anchor",
          target: "#nosotros_header"
        },
        {
          id: "mision",
          label: "Mision",
          type: "anchor",
          target: "#mission_header"
        },
        {
          id: "refinacion",
          label: "Refinación Comercial",
          type: "anchor",
          target: "#refinacion_header"
        },
        {
          id: "contact",
          label: "Contacto",
          type: "anchor",
          target: "#contact_header"
        }
      ]
    },
    footer: true
  },
  "gym-challenge": {
    header: {
      active: true,
      logo: {
        target: "/",
        height: "29px",
        width: "180px",
        alt: "First Metal",
        image: "/image/header_logo.svg"
      },
      navLabel: "First Metal navigation menu",
      navigation: [
        {
          id: "nosotros",
          label: "Nosotros",
          type: "anchor",
          target: "#nosotros_header"
        },
        {
          id: "mision",
          label: "Mision",
          type: "anchor",
          target: "#mission_header"
        },
        {
          id: "refinacion",
          label: "Refinación Comercial",
          type: "anchor",
          target: "#refinacion_header"
        },
        {
          id: "contact",
          label: "Contacto",
          type: "anchor",
          target: "#contact_header"
        }
      ]
    }
  }
}

const ROUTES = {
  "first-metal": [
    {
      key: "Index",
      path: "/",
      exact: true,
      content: "SiteBody",
      layout: "default"
    },
    {
      key: "Contacto",
      path: "/contact",
      content: "ContactForm",
      layout: "full"
    },
    {
      key: "Invitation",
      path: "/invitation/:token",
      content: "CustomMessage",
      layout: "full"
    }
  ],
  "gym-challenge": [
    {
      key: "Index",
      path: "/",
      exact: true,
      content: "QuestionaryTest",
      layout: "clean"
    }
  ]
}

export const API_RESPONSE = [
  {
    VideoBackground: {
      key: "main_video",
      videoPath: "/video/first-metal.mp4",
      videoPathMobile: "/video/first-metal-mobile.mp4",
      ariaLabel: "First Metal video description and link",
      title: "First Metal",
      subTitle: "Un nuevo significado de servicio",
      button: {
        ref: "#perfil_header",
        text: "Perfil Corporativo"
      }
    }
  },
  {
    Sections: {
      ariaLabel: "nosotros_header",
      jss: ["default", "container_acerca"],
      Fragment: [
        {
          TitleText: {
            holder: true,
            title: "acerca de la empresa",
            titleId: "nosotros_header",
            titleLevel: 2,
            body:
              "First Metal® es una organización privada con operaciones integradas para la fundición, refinación y comercialización de metales preciosos. Es una empresa líder en el procesamiento de metales preciosos, respaldada por altos valores, tecnología de vanguardia, destacados socios comerciales y por un serio equipo de profesionales. Todo ello representa las bases de nuestras estrategias de crecimiento a corto, mediano y largo plazo.",
            jssHolder: ["container_summary"],
            jssBody: ["item_text"]
          }
        },
        {
          Image: {
            webp: true,
            alt: "Lingotes de oro y plata",
            imgPath: "/image/gold-silver-bars",
            jssHolder: ["container_image_holder"],
            jssImage: ["container_image"]
          }
        }
      ]
    },
    jss: ["default", "constrain", "padded", "white", "row_empty_from_left"],
    effect: "row_animate_from_left"
  },
  {
    Columns: [
      {
        Sections: [
          {
            id: "perfil_header",
            ariaLabel: "Conceptos de la Empresa",
            jss: ["container_conceptos"],
            ItemsList: {
              className: ["list", "list_conceptos"], // nesteado en itemListSettings
              children: [
                {
                  Item: {
                    className: ["item"], // nestedo en itemSettings
                    "aria-labelledby": "S{i}_header", // CODE THIS, nesteado en itemSettings
                    children: [
                      {
                        Image: {
                          webp: true,
                          alt: "Acciones",
                          imgPath: "/image/stocks",
                          jssHolder: ["item2_bg_image_holder"],
                          jssImage: ["item2_bg_image"]
                        }
                      },
                      {
                        TitleText: {
                          holder: true,
                          title: "Nivel de competencia",
                          titleId: "S{i}_header",
                          titleLevel: 3,
                          body:
                            "El crecimiento de la industria en México ha permitido a First Metal® incrementar sus operaciones y competir en mercados regionales, nacionales e internacionales con una amplia gama de productos y servicios sumamente competitivos.",
                          jssHolder: ["item2_summary"],
                          jssTitle: ["item2_name"],
                          jssBody: ["item2_text"]
                        }
                      }
                    ]
                  }
                },
                {
                  Item: {
                    className: ["item"], // FALTA ESTO, nestedo en itemSettings
                    "aria-labelledby": "S{i}_header", // CODE THIS, nesteado en itemSettings
                    children: [
                      {
                        Image: {
                          webp: true,
                          alt: "Planeación",
                          imgPath: "/image/planning",
                          jssHolder: ["item2_bg_image_holder"],
                          jssImage: ["item2_bg_image"]
                        }
                      },
                      {
                        TitleText: {
                          holder: true,
                          title: "Nuevos Conceptos de Suministro y Servicio",
                          titleId: "S{i}_header",
                          titleLevel: 3,
                          body:
                            "Las actividades tradicionales de refinación evolucionan para definir nuevos conceptos de suministro y servicio; así como nuevas estrategias de negociación, innovando en la eficiencia de los procesos, dentro de los más altos estándares de calidad y mejorando los tiempos y condiciones de pago.",
                          jssHolder: ["item2_summary"],
                          jssTitle: ["item2_name"],
                          jssBody: ["item2_text"]
                        }
                      }
                    ]
                  }
                },
                {
                  Item: {
                    className: ["item"], // FALTA ESTO, nestedo en itemSettings
                    "aria-labelledby": "S{i}_header", // CODE THIS, nesteado en itemSettings
                    children: [
                      {
                        Image: {
                          webp: true,
                          alt: "Campo eólico",
                          imgPath: "/image/sustentable",
                          jssHolder: ["item2_bg_image_holder"],
                          jssImage: ["item2_bg_image"]
                        }
                      },
                      {
                        TitleText: {
                          holder: true,
                          title: "Desarrollo Sustentable",
                          titleId: "S{i}_header",
                          titleLevel: 3,
                          body:
                            "El Desarrollo sustentable es un factor clave para operar de manera responsable, buscando la integración de actividades y objetivos económicos con la calidad de vida y preservación del entorno. Estamos convencidos que la sustentabilidad es una buena inversión y un facto de permanencia de nuestro negocio.",
                          jssHolder: ["item2_summary"],
                          jssTitle: ["item2_name"],
                          jssBody: ["item2_text"]
                        }
                      }
                    ]
                  }
                },
                {
                  Item: {
                    className: ["item"], // FALTA ESTO, nestedo en itemSettings
                    "aria-labelledby": "S{i}_header", // CODE THIS, nesteado en itemSettings
                    children: [
                      {
                        Image: {
                          webp: true,
                          alt: "Monedas",
                          imgPath: "/image/coins",
                          jssHolder: ["item2_bg_image_holder"],
                          jssImage: ["item2_bg_image"]
                        }
                      },
                      {
                        TitleText: {
                          holder: true,
                          title: "Asociaciones Comerciales",
                          titleId: "S{i}_header",
                          titleLevel: 3,
                          body:
                            "La naturaleza global del negocio es reflejo de nuestros socios comerciales en Suiza, Estados Unidos, como es el caso de Metalor Technologies, que es una de las más prestigiosas compañias, cuyas barras de oro, plata, platino y paladio son aceptados como Good London Delivery (GDL) en el mercado internacional.",
                          jssHolder: ["item2_summary"],
                          jssTitle: ["item2_name"],
                          jssBody: ["item2_text"]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    ],
    jss: ["default", "white", "row_empty_from_top"],
    effect: "row_animate_from_top"
  },
  {
    Columns: [
      {
        jss: ["column_mision"],
        Sections: [
          {
            ariaLabel: "mission_header",
            TitleText: {
              __parseTitle: true,
              title: "<b>misión</b> y estrategia",
              titleId: "mission_header",
              titleLevel: 2,
              jssTitle: ["item_mission_name"],
              body:
                "Superar las expectativas de nuestros clientes en calidad, entrega y costo a través de la mejora continua y la interacción con el cliente. Ofrecer un valor agregado a nuestros clientes, accionistas e inversores, en una cultura de rendimiento, innovación, confianza y respeto. Sustentando nuestra misión con renovación constante, mediante educación continua, el aprendizaje y la aplicación de las nuevas tecnologías y mejores prácticas empresariales."
            }
          }
        ]
      },
      {
        jss: ["column_valores"],
        Sections: [
          {
            ariaLabel: "Valores de la empresa",
            ItemsList: {
              className: ["list", "list_valores"], // nesteado en itemListSettings
              children: [
                {
                  Item: {
                    className: ["item"], // FALTA ESTO, nestedo en itemSettings
                    "aria-labelledby": "S{i}_header", // CODE THIS, nesteado en itemSettings
                    TitleText: {
                      title: "VALOR AGREGADO",
                      titleId: "S{i}_header",
                      titleLevel: 3,
                      body:
                        "A nuestros clientes les proveeremos productos y servicios de la más alta calidad, que logren generar soluciones, añadir valor y superar todas las expectativas. Se nos reconocerá como líderes del mercado, socios comerciales confiables y los proveedores preferidos.",
                      jssTitle: ["item3_name"],
                      jssBody: ["item3_text"]
                    }
                  }
                },
                {
                  Item: {
                    className: ["item"], // FALTA ESTO, nestedo en itemSettings
                    "aria-labelledby": "S{i}_header", // CODE THIS, nesteado en itemSettings
                    TitleText: {
                      title: "PERSONAL ALTAMENTE CAPACITADO",
                      titleId: "S{i}_header",
                      titleLevel: 3,
                      body:
                        "Motivamos a nuestro personal y le proporcionamos las herramientas y la capacitación necesarias para crear un valor agregado excepcional para clientes internos y externos. Ofrecemos igualdad de oportunidades, un ambiente y entorno laboral saludable y seguro; así como beneficios y retribuciones competitivas.",
                      jssTitle: ["item3_name"],
                      jssBody: ["item3_text"]
                    }
                  }
                },
                {
                  Item: {
                    className: ["item"], // FALTA ESTO, nestedo en itemSettings
                    "aria-labelledby": "S{i}_header", // CODE THIS, nesteado en itemSettings
                    TitleText: {
                      title: "RELACIONES A LARGO PLAZO",
                      titleId: "S{i}_header",
                      titleLevel: 3,
                      body:
                        "Desarrollamos relaciones de mutuo beneficio a largo plazo con representantes, socios financieros y proveedores de servicios que estén decididos a superar nuestras expectativas.",
                      jssTitle: ["item3_name"],
                      jssBody: ["item3_text"]
                    }
                  }
                },
                {
                  Item: {
                    className: ["item"], // FALTA ESTO, nestedo en itemSettings
                    "aria-labelledby": "S{i}_header", // CODE THIS, nesteado en itemSettings
                    TitleText: {
                      title: "RENDIMIENTO Y RENTABILIDAD",
                      titleId: "S{i}_header",
                      titleLevel: 3,
                      body:
                        "Generaremos crecimiento, rentabilidad, una eficiente tesorería y rendimiento de la inversión, mejorando el rendimiento ambiental y manteniendo los más altos niveles de normas éticas; con lo que, además, maximizaremos el valor de los accionistas.",
                      jssTitle: ["item3_name"],
                      jssBody: ["item3_text"]
                    }
                  }
                }
              ]
            }
          }
        ]
      }
    ],
    jss: ["twoColumns"]
  },
  {
    VideoBackground: {
      key: "secondary_video",
      videoPath: "/video/furnace.mp4",
      ariaLabel: "First Metal estándares",
      title: "Nuevos estándares",
      subTitle:
        "Un nuevo significado de servicioNuestra capacidad de reducir los tiempos de espera y procesos de logística ha dado a nuestros clientes un nuevo significado al término LIQUIDEZ.",
      button: {
        ref: "#refinacion_header",
        text: "Más información",
        jss: ["item_video_two"]
      }
    }
  },
  {
    Columns: {
      jss: ["column_services"],
      Sections: [
        {
          ariaLabel: "refinacion_header",
          Fragment: {
            children: [
              {
                TitleText: {
                  holder: true,
                  title: "acerca de la empresa",
                  titleId: "nosotros_header",
                  titleLevel: 2,
                  body:
                    "First Metal® es una organización privada con operaciones integradas para la fundición, refinación y comercialización de metales preciosos. Es una empresa líder en el procesamiento de metales preciosos, respaldada por altos valores, tecnología de vanguardia, destacados socios comerciales y por un serio equipo de profesionales. Todo ello representa las bases de nuestras estrategias de crecimiento a corto, mediano y largo plazo.",
                  jssHolder: ["container_summary"],
                  jssBody: ["item_text"]
                }
              },
              {
                Image: {
                  webp: true,
                  alt: "Lingotes de oro y plata",
                  imgPath: "/image/gold-silver-bars",
                  jssHolder: ["container_image_holder"],
                  jssImage: ["container_image"]
                }
              }
            ]
          }
        }
      ]
    },
    jss: ["default", "constrain", "white", "padded", "row_fade_off"],
    effect: "row_fade_in"
  }
]

const WIDGETS = {
  "first-metal": [{ name: "ScrollTop" }],
  "gym-challenge": []
}

export const routes = ROUTES[PROJECT]
export const layout = LAYOUTS[PROJECT]
export const widgets = WIDGETS[PROJECT]
