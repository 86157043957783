import "react-app-polyfill/ie11"

import React from "react"
import ReactDOM from "react-dom"

import { layout, routes, widgets } from "./API"

import App from "./App"

import "./index.css"
// import * as serviceWorker from './serviceWorker';

import { StateProvider } from "./store"

ReactDOM.render(
  <StateProvider>
    <App layout={layout} routes={routes} widgets={widgets} />
  </StateProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
