import React from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"

const fullLayoutStyles = createUseStyles({
  wrapper: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center"
  }
})

const FullLayout = ({ children }) => {
  const JSS = fullLayoutStyles()

  return <main className={JSS.wrapper}>{children}</main>
}

FullLayout.propTypes = {
  children: PropTypes.node
}

FullLayout.defaultProps = {
  children: <h1>Loading...</h1>
}

export default FullLayout
