/* eslint-disable camelcase */
import React, { useContext, Suspense, lazy } from "react"
import PropTypes from "prop-types"
import { Route, Switch, HashRouter } from "react-router-dom"

import { SET_TOKEN } from "./actions"
import { store } from "./store"

import FullLayout from "./layouts/full" // Do this layout on CSS for Suspense and lazy load this

const Header = lazy(() => import("./components/Header"))
const Footer = lazy(() => import("./components/Footer"))

const Components = {
  SiteBody: lazy(() => import("./components/SiteBody")),
  NotFound: lazy(() => import("./components/NotFound")),
  ContactForm: lazy(() => import("./components/ContactForm")),
  CustomMessage: lazy(() => import("./components/CustomMessage")),
  QuestionaryTest: lazy(() => import("./components/QuestionaryTest"))
}

const Widgets = {
  ScrollTop: lazy(() => import("./widgets/ScrollTop"))
}

const reCAPTCHA = window.grecaptcha

const App = ({ ...props }) => {
  const { layout, routes, widgets } = props

  const { active: activeHeader, navLabel, navigation, logo } = layout.header

  const {
    dispatch,
    state: { token, reCAPTCHA_KEY }
  } = useContext(store)

  reCAPTCHA.ready(() => {
    if (!token) {
      reCAPTCHA.execute(reCAPTCHA_KEY).then(newToken => {
        dispatch({ type: SET_TOKEN, payload: newToken })
      })
    }
  })

  return (
    <HashRouter>
      <Suspense
        fallback={
          <FullLayout>
            <div>Loading...</div>
          </FullLayout>
        }
      >
        <Switch>
          {routes.map(route => {
            const Component = Components[route.content]

            if (!route.layout || route.layout === "clean") {
              return (
                <Route key={route.key} exact={route.exact} patch={route.path}>
                  <Component />
                </Route>
              )
            }

            if (route.layout === "full") {
              return (
                <Route key={route.key} exact={route.exact} path={route.path}>
                  <FullLayout>
                    <Component />
                  </FullLayout>
                </Route>
              )
            }

            return (
              <Route key={route.key} exact={route.exact} path={route.path}>
                {activeHeader && (
                  <Header logo={logo} nav={navigation} navLabel={navLabel} />
                )}
                <Component />
                {layout.footer && <Footer />}
              </Route>
            )
          })}

          <Route component={Components.NotFound} />
        </Switch>
        {widgets.map(widget => {
          const Widget = Widgets[widget.name]
          return <Widget key={widget.name} />
        })}
      </Suspense>
    </HashRouter>
  )
}

App.propTypes = {
  layout: PropTypes.shape({
    header: PropTypes.shape({
      active: PropTypes.bool.isRequired,
      logo: PropTypes.shape({
        target: PropTypes.string.isRequired,
        height: PropTypes.string.isRequired,
        width: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired
      }),
      navLabel: PropTypes.string,
      navigation: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          target: PropTypes.string
        })
      )
    }),
    footer: PropTypes.bool
  }),
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      layout: PropTypes.string,
      exact: PropTypes.bool
    })
  ),
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  )
}

App.defaultProps = {
  layout: {
    header: {
      active: true,
      logo: {
        target: "/",
        height: 29,
        widgth: 180,
        alt: "MDP",
        image: "logo.svg"
      },
      navigation: []
    },
    footer: true
  },
  routes: [],
  widgets: []
}

export default App
