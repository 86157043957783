import React, { createContext, useReducer } from "react"
import { SET_TOKEN } from "./actions"

const initialState = {
  API_URL: "https://us-central1-first-metal.cloudfunctions.net",
  reCAPTCHA_KEY: "6Lcgz8QUAAAAADXyYfPoN4HsL7vpJYgOAefjqMPB",
  token: null
}

const store = createContext(initialState)
const { Provider } = store

// eslint-disable-next-line react/prop-types
const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((newState, action) => {
    switch (action.type) {
      case SET_TOKEN:
        return { ...newState, token: action.payload }
      default:
        throw new Error(`Action not recognized, ${action.type}`)
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, StateProvider }
